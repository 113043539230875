import axios from 'axios'
import { header_token } from '@/website/token'
import {
    shopCallbackService,
    stockService_dotnet,
    ecomService_dotnet,
    shopService_dotnet, 
  } from "@/website/global";

export async function get_warehouse_list(shopid) {
    let response = await axios.post(shopService_dotnet + 'Shop/get-warehouse-list',{'shopID':shopid},{ headers: header_token});
    return response;
}
